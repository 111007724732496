import { chains } from 'src/libs/rainbow';
import { Address } from 'viem';
import { useChainId } from 'wagmi';

const MAP_VAULT_ADDRESS = {
  [chains.bitLayer.id]: process.env.REACT_APP_BITLAYER_VAULT_CONTRACT_ADDRESS,
  [chains.ethereum.id]: process.env.REACT_APP_ETHEREUM_VAULT_CONTRACT_ADDRESS,
  [chains.sei.id]: process.env.REACT_APP_SEI_VAULT_CONTRACT_ADDRESS,
} satisfies Record<number, Address>;

export const useVaultAddress = () => {
  const wagmiStorage = localStorage.getItem('wagmi.store');
  // console.log('🚀 ~ useVaultAddress ~ wagmiStorage:', wagmiStorage);

  if (wagmiStorage && JSON.parse(wagmiStorage!).state.chainId == 200901) {
    localStorage.removeItem('wagmi.store');
  }

  let chainId = useChainId();
  // console.log('🚀 ~ useVaultAddress ~ chainId:', chainId);

  if (chainId == 200901) {
    chainId = 200810;
  }
  // console.log('🚀 ~ useVaultAddress ~ chainId2:', chainId);

  const address = MAP_VAULT_ADDRESS[chainId];

  if (!address) {
    throw new Error(`Unsupported network ${chainId}`);
  }

  return address;
};
