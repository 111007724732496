import { Address } from 'viem';
import { GetOrderListBookFilter } from './get-order-list-book';

export const orderKeys = {
  all: ['order'] as const,

  orderListBookLists: () => [...orderKeys.all, 'orderListBook'] as const,
  orderListBook: (filter: GetOrderListBookFilter) =>
    [...orderKeys.orderListBookLists(), filter] as const,
  userOrdersKey: () => [...orderKeys.all, 'userOrders'] as const,
  userOrders: (userId: string) => [...orderKeys.userOrdersKey(), userId] as const,
  supportedPairs: () => [...orderKeys.all, 'supportedPairs'] as const,

  postions: () => [...orderKeys.all, 'postions'] as const,
  position: (walletAddress: Address) => [...orderKeys.postions(), walletAddress] as const,
};
