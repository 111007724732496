import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { QueryOptions } from 'src/libs/react-query';
import { Address } from 'viem';
import { useChainId } from 'wagmi';
import { tokenKeys } from './token-keys';

export interface Pair {
  id: string;
  baseToken: string;
  baseTokenAddress: Address;
  baseTokenDecimal: number;
  quoteToken: string;
  quoteTokenAddress: Address;
  quoteTokenDecimal: number;
}

export type GetSupportedPairsResponse = Array<Pair>;

export const getSupportedPairs = async (chainId: number) => {
  if (chainId === 200901) {
    chainId = 200810;
  }
  const { data } = await apiClient.get<GetSupportedPairsResponse>('/v1/tokens/support-pairs', {
    params: {
      chainId,
    },
  });

  return data;
};

export type UseGetSupportedPairsOptions<TData, TError> = QueryOptions<
  GetSupportedPairsResponse,
  TError,
  TData,
  ReturnType<typeof tokenKeys.networkSupportedPairs>
>;

export const useGetSupportedPairs = <TData = GetSupportedPairsResponse, TError = Error>(
  options?: UseGetSupportedPairsOptions<TData, TError>,
) => {
  const chainId = useChainId();

  return useQuery({
    queryKey: tokenKeys.networkSupportedPairs(chainId),
    queryFn: () => getSupportedPairs(chainId),
    ...options,
  });
};
