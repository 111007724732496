import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { useAuth } from 'src/libs/rainbow';
import { QueryOptions } from 'src/libs/react-query';
import { useAccount } from 'wagmi';
import { userKeys } from './user-keys';

export interface Balance {
  balance: number;
  token_name: string;
}

export type GetPortfolioResponse = Balance[];

export const getPortfolio = async () => {
  const { data } = await apiClient.get<GetPortfolioResponse>(`/v1/user/getPortfolio`);

  console.log('🚀 ~getPortfolio ~ data:', data);
  return data ?? [];
};

export type UseGetPortfolioOptions<TData, TError> = QueryOptions<
  GetPortfolioResponse,
  TError,
  TData,
  ReturnType<typeof userKeys.allPortfolio>
>;

export const useGetPortfolio = <TData = GetPortfolioResponse, TError = Error>(
  options?: UseGetPortfolioOptions<TData, TError>,
) => {
  const { address: userAddress } = useAccount();
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: userKeys.allPortfolio(userAddress!),
    queryFn: () => getPortfolio(),
    enabled: isAuthenticated,
    ...options,
  });
};
