import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { useAuth } from 'src/libs/rainbow';
import { QueryOptions } from 'src/libs/react-query';
import { useAccount } from 'wagmi';
import { userKeys } from './user-keys';

export interface Balance {
  balance: number;
  token_name: string;
}

export type GetBalancesResponse = Balance[];

export const getBalances = async () => {
  const { data } = await apiClient.get<GetBalancesResponse | null>(`/v1/user/balance`);

  console.log('🚀 ~ getBalances ~ data:', data);
  return data ?? [];
};

export type UseGetBalanceOptions<TData, TError> = QueryOptions<
  GetBalancesResponse,
  TError,
  TData,
  ReturnType<typeof userKeys.allBalances>
>;

export const useGetBalances = <TData = GetBalancesResponse, TError = Error>(
  options?: UseGetBalanceOptions<TData, TError>,
) => {
  const { address: userAddress } = useAccount();
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: userKeys.allBalances(userAddress!),
    queryFn: () => getBalances(),
    enabled: isAuthenticated,
    ...options,
  });
};
