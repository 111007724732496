import {
  AuthenticationStatus,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  createAuthenticationAdapter,
  darkTheme,
  getDefaultConfig,
  lightTheme,
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { SiweMessage } from 'siwe';
import { SignerProvider } from 'src/contexts/SignerContext';
import { SocketProvider } from 'src/contexts/SocketContext';
import { createContext } from 'src/helpers/context';
import { accessTokenIAO, removeAuthTokens } from 'src/helpers/storage';
import { returnRawValue, useLocalStorage } from 'src/hooks/useLocalStorage';
import { THEME_MODE } from 'src/interfaces/theme';
import { useLogin } from 'src/services/auth/login';
import { useLogout } from 'src/services/auth/logout';
import { useSetCombinePublicKey } from 'src/services/auth/set-combined-public-key';
import { useVerifyToken } from 'src/services/auth/verify';
import { userKeys } from 'src/services/user/user-keys';
import { useAppSelector } from 'src/store';
import { defineChain } from 'viem';
import { mainnet, seiDevnet, sepolia } from 'viem/chains';
import { WagmiProvider, useAccount, useChainId } from 'wagmi';
import { queryClient } from './react-query';

const useTestnet = process.env.REACT_APP_TESTNET === 'true';

const bitLayerTestnet = defineChain({
  id: 200810,
  name: 'Bitlayer Testnet',
  iconUrl: 'https://img.cryptorank.io/coins/bitlayer1708427921502.png',
  iconBackground: '#fff',
  nativeCurrency: { name: 'BitCoin', symbol: 'BTC', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.bitlayer.org'] },
  },
  blockExplorers: {
    default: { name: 'BitLayer Testnet', url: 'https://testnet.btrscan.com' },
  },
  testnet: true,
});

const bitLayerMainnet = defineChain({
  id: 200901,
  name: 'Bitlayer',
  iconUrl: 'https://img.cryptorank.io/coins/bitlayer1708427921502.png',
  iconBackground: '#fff',
  nativeCurrency: { name: 'BitCoin', symbol: 'BTC', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.bitlayer.org/'] },
  },
  blockExplorers: {
    default: { name: 'BitLayer', url: 'https://www.btrscan.com/' },
  },
});

export const sei = defineChain({
  id: 1329,
  name: 'Sei',
  nativeCurrency: { name: 'Sei', symbol: 'SEI', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://evm-rpc.sei-apis.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Seitrace',
      url: 'https://seitrace.com',
    },
  },
});

export const getChains = (useTestnet: boolean) => {
  if (useTestnet) {
    return { bitLayer: bitLayerTestnet, ethereum: sepolia, sei: seiDevnet };
  }

  return { bitLayer: bitLayerMainnet, ethereum: mainnet, sei };
};

export const chains = getChains(useTestnet);

export const config = getDefaultConfig({
  appName: 'dex',
  projectId: '85e9ebb34585fc3bde5e0ed8cb752d6b',
  chains: [chains.bitLayer, chains.ethereum, chains.sei],
  pollingInterval: 5000,
});

const [AuthStatusProvider, useAuthStatus] = createContext({
  hookName: 'useAuthStatus',
  providerName: 'AuthStatusProvider',
});

export const SiweProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();

  const [accessToken] = useLocalStorage(accessTokenIAO, undefined, {
    serialize: returnRawValue,
    deserialize: returnRawValue,
  });

  const { address: userAddress } = useAccount();
  const verifyTokenQuery = useVerifyToken();
  const loginMutation = useLogin();
  const logoutMutation = useLogout({ onSettled: removeAuthTokens });
  const setCombinePublicKeyMutation = useSetCombinePublicKey();
  const chainId = useChainId();

  const authenticationAdapter = useMemo(
    () =>
      createAuthenticationAdapter({
        getNonce: async () => {
          return 'nonce';
        },

        createMessage: ({ address, chainId }) => {
          return new SiweMessage({
            domain: window.location.host,
            address,
            statement: 'Sign in with Ethereum to the app.',
            uri: window.location.origin,
            version: '1',
            chainId,
          });
        },

        getMessageBody: ({ message }) => {
          return message.prepareMessage();
        },

        verify: async ({ message, signature }) => {
          await loginMutation.mutateAsync({
            address: userAddress!,
            signature,
            message: message.prepareMessage(),
          });
          // const userPublicKey = JSON.parse(localStorage.getItem(`signerData-${userAddress}`) ?? '');
          // console.log('🚀 ~ verify: ~ userPublicKey:', userPublicKey);
          // if (userPublicKey == '') {
          //   setCombinePublicKeyMutation.mutate({
          //     chainID: chainId,
          //     publicKey: userPublicKey.publicKey,
          //   });
          // }

          return true;
        },

        signOut: async () => {
          /**
           * Invalidate auth tokens and clear related wallet data if signed out by disconnecting or changing the wallet.
           * If signOut function is called by canceling the sign message pop up, don't do anything.
           */
          if (accessToken) {
            logoutMutation.mutate();

            await queryClient.resetQueries({
              queryKey: userKeys.all,
            });
          }
        },
      }),
    [loginMutation, logoutMutation, userAddress, accessToken],
  );

  useEffect(() => {
    if (verifyTokenQuery.isError) {
      removeAuthTokens();
    }
  }, [verifyTokenQuery.isError]);

  let status: AuthenticationStatus;
  if (verifyTokenQuery.isSuccess) {
    status = 'authenticated';
  } else if (verifyTokenQuery.isFetching) {
    status = 'loading';
  } else {
    status = 'unauthenticated';
  }

  return (
    <RainbowKitAuthenticationProvider adapter={authenticationAdapter} status={status}>
      <AuthStatusProvider value={status}>{children}</AuthStatusProvider>
    </RainbowKitAuthenticationProvider>
  );
};

export const useAuth = () => {
  const authStatus = useAuthStatus();

  return {
    status: authStatus,
    isAuthenticated: authStatus === 'authenticated',
    isUnauthenticated: authStatus === 'unauthenticated',
    isPending: authStatus === 'loading',
  };
};

const rkDarkTheme = darkTheme();
const rkLightTheme = lightTheme();

export const RainbowProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useAppSelector((state) => state.theme);

  const rkTheme = theme === THEME_MODE.DARK ? rkDarkTheme : rkLightTheme;

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <SiweProvider>
          <RainbowKitProvider initialChain={bitLayerTestnet.id} theme={rkTheme}>
            <SignerProvider>
              <SocketProvider>{children}</SocketProvider>
            </SignerProvider>
          </RainbowKitProvider>
        </SiweProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
