import BigNumber from 'bignumber.js';

export const positiveNumberValidator = (message?: string) => (_: unknown, value: number) => {
  if (new BigNumber(value).isGreaterThan(0)) {
    return Promise.resolve();
  }

  return Promise.reject(message || 'Must be a positive number');
};

export const maxValidator =
  (maxAmount: number, message?: string) => (_: unknown, value: number) => {
    console.log('MAXAMOUNT', maxAmount, value);

    const bigNumberValue = new BigNumber(value);
    console.log(
      '🚀 ~ bigNumberValue:',
      bigNumberValue,
      bigNumberValue.isLessThanOrEqualTo(maxAmount),
    );

    if (bigNumberValue.isLessThanOrEqualTo(maxAmount)) {
      return Promise.resolve();
    }

    return Promise.reject(message || `Must be less than or equal to ${maxAmount.toFixed(3)}`);
  };

export const minValidator =
  (minAmount: number, message?: string) => (_: unknown, value: number) => {
    const bigNumberValue = new BigNumber(value);

    if (bigNumberValue.isGreaterThanOrEqualTo(minAmount)) {
      return Promise.resolve();
    }

    return Promise.reject(message || `Must be greater than or equal to ${minAmount.toFixed(3)}`);
  };
