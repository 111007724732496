import { Key, PublicNonces } from '@borislav.itskov/schnorrkel.js';
import { useQueryClient } from '@tanstack/react-query';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import secureLocalStorage from 'react-secure-storage';
import { io, Socket } from 'socket.io-client';
import { decrypt, safeJSONParse } from 'src/helpers/encryption';
import { storeSignature } from 'src/helpers/save-signatures';
import { accessTokenIAO } from 'src/helpers/storage';
import { useAuth } from 'src/libs/rainbow';
import Signer from 'src/services/auth/key-pair';
import { orderKeys } from 'src/services/order/order-keys';
import { useAccount } from 'wagmi';
import { useSigner } from './SignerContext';

const URL = process.env.REACT_APP_SERVICE_SOCKET ?? '';

interface SocketContextProps {
  signatureSocket: Socket | null;
  isSignatureSocketInitialized: boolean;
  isBalanceSocketInitialized: boolean;
  userBalance: number;
}

const SocketContext = createContext<SocketContextProps | undefined>(undefined);

interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const [signatureSocket, setSignatureSocket] = useState<Socket | null>(null);
  const [balanceSocket, setBalanceSocket] = useState<WebSocket | null>(null);
  const [userBalance, setUserBalance] = useState(0);
  const { getPublicNonces, getPublicKey, multiSignMessage, signer } = useSigner();
  const [isSignatureSocketInitialized, setIsSignatureSocketInitialized] = useState(false);
  const [isBalanceSocketInitialized, setIsBalanceSocketInitialized] = useState(false);
  const { isAuthenticated } = useAuth();
  const { address } = useAccount();

  const initializeBalanceSocket = (token: string) => {
    console.log('socket initialize');
    if (balanceSocket) return;
    console.log('socket token', `${URL}/api-gateway/v1/ws`);
    const ws = new WebSocket(`${URL}/api-gateway/v1/ws?accessToken=${token}`); // const newSocket = io(URL, {
    //   path: '/v1/market/ws/',
    //   // extraHeaders: {
    //   //   Authorization: `Bearer ${token}`,
    //   // },
    // });

    console.log('🚀 ~ initializeBalanceSocket ~ newSocket:', ws);
    setBalanceSocket(ws);
    setIsBalanceSocketInitialized(true);
  };

  const closeSockets = useCallback(() => {
    console.log('Attempting to close sockets');
    if (balanceSocket) {
      console.log('Closing balance socket');
      balanceSocket.close();
      setBalanceSocket(null);
      setIsBalanceSocketInitialized(false);
    }
    // if (signatureSocket) {
    //   console.log('Disconnecting signature socket');
    //   signatureSocket.disconnect();
    //   setSignatureSocket(null);
    //   setIsSignatureSocketInitialized(false);
    // }
  }, [balanceSocket, signatureSocket]);
  const initializeSocket = (token: string) => {
    if (signatureSocket) return;
    console.log('socket token', token, `${URL}/signature-service/ws/`);

    const newSocket = io(URL, {
      path: '/signature-service/ws/',
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    setSignatureSocket(newSocket);
    setIsSignatureSocketInitialized(true);
  };

  const generateNonceToSend = (data: any) => {
    const storedData = secureLocalStorage.getItem(`signerData-${address}`) as string;
    const decryptedData = decrypt(storedData, 'vDex', address as string);
    let privateKey;
    if (decryptedData) {
      const parsedData = safeJSONParse(decryptedData);
      console.log('🚀 ~ generateNonceToSend ~ parsedData:', parsedData);

      if (parsedData && parsedData.privateKey) {
        privateKey = parsedData.privateKey;
      } else {
        console.error('Failed to parse decrypted data or missing privateKey');
        // Handle this error case - maybe clear the corrupted data and generate new keys
      }
    } else {
      console.error('Decryption failed');
      // Handle this error case - maybe clear the corrupted data and generate new keys
    }
    // const { privateKey } = JSON.parse(decryptedData!);
    const localSigner = new Signer(privateKey!);
    signersData[data.id] = localSigner;
    const nonces = localSigner.getPublicNonces();
    console.log('🚀 ~ onNewTrade ~ nonces:', nonces);
    const publicKey = localSigner.getPublicKey()?.toHex();
    console.log('🚀 ~ onNewTrade ~ publicKey:', publicKey);
    const sigData = {
      publicKey: publicKey,
      publicNonce: {
        kPublic: localSigner.getPublicNonces()?.kPublic.toHex(),
        kTwoPublic: localSigner.getPublicNonces()?.kTwoPublic.toHex(),
      },
      signatureID: data.id,
    };

    return sigData;
  };
  let signersData: Signer[] = [];
  console.log('🚀 ~ signersData:', signersData);
  useEffect(() => {
    if (signatureSocket) {
      const onConnect = () => {
        console.log('socket connect');
        signatureSocket.emit('onConnect', 'connected to server');
      };

      const onDisconnect = () => {
        console.log('socket disconnect');
      };

      const onConnectReply = (data: any) => {
        console.log('socket onconnect reply', data, signatureSocket.id);
        const signatures = data?.signatures;

        if (signatures.length > 0) {
          signatures.map((sig: any) => {
            const sigData = generateNonceToSend(sig);
            console.log('🚀 ~ signatures.map ~ sigData:', sigData, signersData);
            signatureSocket.emit('exchangeNonce', sigData);
          });
        }
      };

      const onNewTrade = (data: any) => {
        const sigData = generateNonceToSend(data);

        queryClient.invalidateQueries({ queryKey: orderKeys.position(address!) }),
          // queryClient.invalidateQueries({ queryKey: userKeys.allBalances(address!) }),
          console.log('🚀 ~ onNewTrade ~ sigData:', sigData);
        signatureSocket.emit('exchangeNonce', sigData);
      };

      const onExchangeNonceReply = (signRequestDto: any) => {
        // console.log(
        //   'socket onexchange',
        //   signRequestDto,
        //   signersData,
        //   signersData[signRequestDto.signatureID].getAddress(),
        //   signRequestDto.signatureID,
        // );
        console.log('socket onexchange', signersData, signRequestDto?.signatureID);
        try {
          const nonces = {
            kPublic: Key.fromHex(signRequestDto.publicNonce.kPublic),
            kTwoPublic: Key.fromHex(signRequestDto.publicNonce.kTwoPublic),
          };
          const publicKey = Key.fromHex(signRequestDto.publicKey);
          const publicKeys = [publicKey, signersData[signRequestDto.signatureID].getPublicKey()];
          const platformNonce = signersData[signRequestDto.signatureID].getPublicNonces();
          const publicNonces = [nonces, platformNonce];
          const signature = multiSignMessage(
            signRequestDto.signatureData,
            publicKeys as Key[],
            publicNonces as PublicNonces[],
            signersData[signRequestDto.signatureID],
          );
          const signRes = {
            signature: signature!.signature.toHex(),
            publicKey: signersData[signRequestDto.signatureID].getPublicKey()!.toHex(),
            publicNonce: {
              kPublic: platformNonce!.kPublic.toHex(),
              kTwoPublic: platformNonce!.kTwoPublic.toHex(),
            },
            signatureID: signRequestDto.signatureID,
          };
          signatureSocket.emit('generateSignature', signRes);
        } catch (error) {}
      };

      const onGenerateSignatureReply = (data: any) => {
        console.log('socket generatesignaturereply', data);
        signersData = [];
        storeSignature(JSON.stringify(data), address!);
        // localStorage.setItem('lastSignature', JSON.stringify(data));
      };

      signatureSocket.on('connect', onConnect);
      signatureSocket.on('disconnect', onDisconnect);
      signatureSocket.on('onConnectReply', onConnectReply);
      signatureSocket.on('new-trade-events', onNewTrade);
      signatureSocket.on('exchangeNonceReply', onExchangeNonceReply);
      signatureSocket.on('generateSignatureReply', onGenerateSignatureReply);

      // socket.on('message', onListen);
      return () => {
        signatureSocket.off('connect', onConnect);
        signatureSocket.off('disconnect', onDisconnect);
        signatureSocket.off('onConnectReply', onConnectReply);
        signatureSocket.off('new-trade-events', onNewTrade);
        signatureSocket.off('exchangeNonceReply', onExchangeNonceReply);
        signatureSocket.off('generateSignatureReply', onGenerateSignatureReply);
        // socket!.off('message', onListen);
      };
    }
  }, [signatureSocket, signer]);

  useEffect(() => {
    if (balanceSocket) {
      const onConnect = () => {
        const balanceRequest = {
          event: 'balance.update',
          params: {},
        };
        balanceSocket.send(JSON.stringify(balanceRequest));
      };

      const onMessage = (e: any) => {
        const data = JSON.parse(e.data);
        console.log('🚀 ~ onMessage ~ data:', data);

        if (data.event === 'balance.update' && data.success) {
          const balanceInfo = data.result[0].balance;
          console.log(
            '🚀 ~ onMessage ~ balanceInfo:',
            typeof balanceInfo,
            balanceInfo,
            balanceInfo.toFixed(3),
          );
          setUserBalance(balanceInfo ?? 0);
        }
      };

      balanceSocket.addEventListener('open', onConnect);
      balanceSocket.addEventListener('message', onMessage);

      return () => {
        balanceSocket.removeEventListener('open', onConnect);
        balanceSocket.removeEventListener('message', onMessage);
      };
    }
  }, [balanceSocket]);

  console.log('balanceSocket', balanceSocket);

  useEffect(() => {
    const token = localStorage.getItem(accessTokenIAO);
    console.log('🚀 ~ useEffect ~ token:', token);

    if (token) {
      initializeSocket(token);
      initializeBalanceSocket(token);
    } else {
      const handleStorageChange = () => {
        const newToken = localStorage.getItem(accessTokenIAO);
        if (newToken && !isSignatureSocketInitialized) {
          initializeSocket(newToken);
          initializeBalanceSocket(newToken);
        }
      };

      window.addEventListener('storage', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, [isSignatureSocketInitialized, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      closeSockets();
    }
  }, [isAuthenticated]);

  return (
    <SocketContext.Provider
      value={{
        signatureSocket,
        isSignatureSocketInitialized,
        isBalanceSocketInitialized,
        userBalance,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextProps => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIzN2Y5NTFkZC1mMDJkLTQ3MTctOTM2OS00MmQ0MGE0NjkxZWMiLCJhZGRyZXNzIjoiMHhDRTQzYzZGMjY5QjJBNzQxMjQ2NjRBREU4NDJDRDE1NDdjYTcxNUEzIiwiaWF0IjoxNzI0NzYxNzA0LCJleHAiOjE3MjQ3OTA1MDR9.oNoF3ttZsC7QuavuVoebrjAsYJESA9cglwqaypy9IQE');
