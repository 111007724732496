const SIGNATURE_LIMIT = 1000;

export function storeSignature(signatureObject: any, walletAddress: string) {
  const chainId = JSON.parse(signatureObject).chainID;
  const storedSignatures =
    JSON.parse(localStorage.getItem(`signatures-${walletAddress}-${chainId}`)!) || [];

  if (storedSignatures.length >= SIGNATURE_LIMIT) {
    storedSignatures.shift();
  }

  storedSignatures.push(signatureObject);

  localStorage.setItem(`signatures-${walletAddress}-${chainId}`, JSON.stringify(storedSignatures));
}
