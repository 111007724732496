import { QueryCache, QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import type { QueryKey, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { message } from 'antd';
import { userKeys } from 'src/services/user/user-keys';
import { Address } from 'viem';

const isProduction = process.env.NODE_ENV === 'production';
const MAX_RETRIES = 3;
const RETRY_BLACKLIST_STATUSES = [400, 401, 403, 404];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: isProduction,

      retry: (failureCount, error) => {
        if (!isProduction) {
          return false;
        }

        if (failureCount > MAX_RETRIES) {
          return false;
        }

        if (isAxiosError(error) && RETRY_BLACKLIST_STATUSES.includes(error.response?.status ?? 0)) {
          return false;
        }

        return true;
      },
    },
  },

  queryCache: new QueryCache({
    onError: (error, query) => {
      /**
       * only show error toasts if we already have data in the cache
       * which indicates a failed background update
       */
      if (query.state.data === undefined) {
        return;
      }

      let errorMessage = 'Something went wrong';
      console.error('fetching error', error);

      if (isAxiosError(error) && error.response?.data?.message) {
        errorMessage = error.response?.data?.message;
      }

      message.error(errorMessage);
    },
  }),
});

/**
 * Generates query keys with data that are specific to a user.
 *
 */
export const generateUserQueryKeys = <const K extends readonly unknown[]>(
  walletAddress: Address,
  ...keys: K
) => [...userKeys.all, walletAddress, ...keys] as const;

export type MutationOptions<TData, TError, TVariables, TContext> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'mutationFn'
>;

export type QueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'queryFn' | 'queryKey'
>;
