import Schnorrkel, { Key, KeyPair, PublicNonces } from '@borislav.itskov/schnorrkel.js';
import { secp256k1 } from 'ethereum-cryptography/secp256k1';
import { AbiCoder, Wallet, ethers, hexlify } from 'ethers';
import { CombineSignaturesResponse } from '../user/combine-signatures';

// const schnorrkel = new Schnorrkel();

export default class Signer {
  schnorrkel: Schnorrkel;
  keyPair: KeyPair;
  wallet: Wallet;

  constructor(privateKey: string) {
    this.schnorrkel = new Schnorrkel();
    if (privateKey) {
      this.keyPair = _newKeyPair(privateKey);
    } else {
      this.keyPair = generateRandomKeys();
    }
    const privateKeyHex = this.keyPair.privateKey.toHex();
    // console.log('🚀 ~ Signer ~ constructor ~ privateKeyHex:', privateKeyHex);
    this.wallet = new Wallet(privateKeyHex);
  }
  getPublicKey(): Key {
    return this.keyPair.publicKey;
  }

  getPublicNonces(): PublicNonces {
    if (this.schnorrkel.hasNonces(this.keyPair.privateKey)) {
      return this.schnorrkel.getPublicNonces(this.keyPair.privateKey);
    }
    return this.schnorrkel.generatePublicNonces(this.keyPair.privateKey);
  }

  multiSignMessage(msg: string, publicKeys: Key[], publicNonces: PublicNonces[]) {
    const hash = ethers.keccak256(msg);
    return this.schnorrkel.multiSigSignHash(
      this.keyPair.privateKey,
      hash,
      publicKeys,
      publicNonces,
    );
  }

  getAddress(): string {
    return this.wallet.address;
  }

  async ecdsaSign(message: string): Promise<string> {
    return this.wallet.signMessage(message);
  }
}

export const _newKeyPair = (privateKeyHex: string): KeyPair => {
  const privateKey = Key.fromHex(privateKeyHex);
  const pubKey = Buffer.from(secp256k1.getPublicKey(privateKey.buffer, true));

  const data = {
    publicKey: pubKey,
    privateKey: privateKey.buffer,
  };

  return new KeyPair(data);
};

export const generateRandomKeys = () => {
  const privateKey = secp256k1.utils.randomPrivateKey();

  const pubKey = secp256k1.getPublicKey(privateKey, true);

  const data = {
    publicKey: Buffer.from(pubKey),
    privateKey: Buffer.from(privateKey),
  };

  return new KeyPair(data);
};
export const formatSchnorrForContract = (signatureResult: CombineSignaturesResponse) => {
  // console.log('signatureresult', signatureResult);

  const combinedPubKey = Buffer.from(signatureResult.combinedPublicKey, 'hex');
  const px = combinedPubKey.subarray(1, 33);
  const parity = combinedPubKey[0] - 2 + 27;
  const pxHex = hexlify(px);
  const combinedPublicAddress = '0x' + pxHex.slice(pxHex.length - 40, pxHex.length);

  const abiCoder = new AbiCoder();
  const signatureEncoded = abiCoder.encode(
    ['bytes32', 'bytes32', 'bytes32', 'uint8'],
    [
      px,
      Buffer.from(signatureResult.challenge, 'hex'),
      Buffer.from(signatureResult.signature, 'hex'),
      parity,
    ],
  );

  const _schnorr = {
    data: signatureResult.signatureData,
    signature: signatureEncoded,
    combinedPublicKey: combinedPublicAddress,
  };

  return _schnorr;
};
