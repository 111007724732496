import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { MutationOptions } from 'src/libs/react-query';

export interface SetCombinePublicKeyDto {
  publicKey: string;
  chainID: number;
}

export interface SetCombinePublicKeyResponse {
  data: any;
}

export const setCombinePublicKey = async (setCombinePublicKeyDto: SetCombinePublicKeyDto) => {
  const response = await apiClient.post(
    '/api/v1/signature/setCombinedPublicKey',
    setCombinePublicKeyDto,
    {
      baseURL: process.env.REACT_APP_SIGNATURE_SERVICE_API_ENDPOINT,
    },
  );

  return response.data;
};

export type UseSetCombinePublicKeyOptions<TError = Error, TContext = unknown> = MutationOptions<
  SetCombinePublicKeyResponse,
  TError,
  SetCombinePublicKeyDto,
  TContext
>;

export const useSetCombinePublicKey = <TError = Error, TContext = unknown>(
  options?: UseSetCombinePublicKeyOptions<TError, TContext>,
) => {
  return useMutation({
    mutationFn: setCombinePublicKey,

    ...options,
  });
};
