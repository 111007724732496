import { useQuery } from '@tanstack/react-query';
import { accessTokenIAO } from 'src/helpers/storage';
import { returnRawValue, useLocalStorage } from 'src/hooks/useLocalStorage';
import { ConfigHeaders, apiClient } from 'src/libs/axios';
import { QueryOptions } from 'src/libs/react-query';
import { Address, isAddressEqual } from 'viem';
import { useAccount } from 'wagmi';
import { authKeys } from './auth-keys';
import { logout } from './logout';

export interface VerifyTokenResponse {
  userId: string;
  address: Address;
  iat: number;
  exp: number;
}

export const verifyToken = async (connectedWallet: Address) => {
  const { data } = await apiClient.get<VerifyTokenResponse>('/api/v1/auth/verify', {
    baseURL: process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
    headers: {
      [ConfigHeaders.NO_RELOAD]: true,
    },
  });

  if (isAddressEqual(data.address, connectedWallet) === false) {
    // invalidate auth tokens of the previous connected wallet
    await logout();
    throw new Error('Connected wallet does not match with encoded wallet');
  }

  localStorage.setItem('userId', data.userId);

  return data;
};

export type UseVerifyTokenOptions<TData, TError> = QueryOptions<
  VerifyTokenResponse,
  TError,
  TData,
  ReturnType<typeof authKeys.authenticated>
>;

export const useVerifyToken = <TData = void, TError = Error>(
  options?: UseVerifyTokenOptions<TData, TError>,
) => {
  const [accessToken] = useLocalStorage(accessTokenIAO, undefined, {
    serialize: returnRawValue,
    deserialize: returnRawValue,
  });
  const { address: userAddress } = useAccount();

  return useQuery({
    queryKey: authKeys.authenticated(userAddress!),
    queryFn: () => verifyToken(userAddress!),
    enabled: Boolean(userAddress && accessToken),
    gcTime: 0,
    ...options,
  });
};
