import { generateUserQueryKeys } from 'src/libs/react-query';
import { Address } from 'viem';

export const userKeys = {
  all: ['user'] as const,

  balances: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'balance'),
  allBalances: (walletAddress: Address) => [...userKeys.balances(walletAddress), 'all'] as const,
  portfolio: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'portfolio'),
  allPortfolio: (walletAddress: Address) => [...userKeys.portfolio(walletAddress), 'all'] as const,
  withdrawSchnorrSig: (walletAddress: Address) =>
    generateUserQueryKeys(walletAddress, 'withdrawSchnorrSig'),
  allWithdrawSchnorrSig: (walletAddress: Address) =>
    [...userKeys.balances(walletAddress), 'all'] as const,
  combineSignatures: (walletAddress: Address) =>
    generateUserQueryKeys(walletAddress, 'combineSignatures'),
  allcombineSignatures: (walletAddress: Address) =>
    [...userKeys.balances(walletAddress), 'all'] as const,
};
