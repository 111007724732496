import { ConnectButton, useChainModal } from '@rainbow-me/rainbowkit';
import { Flex, Layout, notification } from 'antd';
import { get } from 'lodash';
import { Moon, Sun } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VirtualIcon } from 'src/assets/icons';
import MenuIcon from 'src/assets/icons/navbar/MenuIcon';
import { PATHS } from 'src/constants/paths';
import { useSocket } from 'src/contexts/SocketContext';
import { setStorageTheme } from 'src/helpers/storage';
import { useDisclosure } from 'src/hooks/useDisclosure';
import useWindowSize from 'src/hooks/useWindowSize';
import { THEME_MODE } from 'src/interfaces/theme';
import { chains, useAuth } from 'src/libs/rainbow';
import { useGetBalances } from 'src/services/user/get-balance';
import { setTheme } from 'src/store/actions/theme';
import { useAccount } from 'wagmi';
import { Button } from '../../ui/button';
import { DepositModal } from './DepositModal';
import SubMenu from './SubMenu';
import './styles.scss';

const { Header } = Layout;

const badgeCountClassName = (count: number) => {
  if (count < 10) return 'count-small';
  if (count >= 10 && count < 100) return 'count-medium';
  if (count >= 100) return 'count-large';
};

interface Props {
  isOpenMenuMobile: boolean;
  setIsOpenMenuMobile: (bool: boolean) => void;
  isOpenNotificationMobile: boolean;
  setIsOpenNotificationMobile: (bool: boolean) => void;
  getCountUnRead: () => void;
  notificationCount: number;
}

const AppHeader: React.FC<Props> = ({
  isOpenMenuMobile,
  setIsOpenMenuMobile,
  isOpenNotificationMobile,
  setIsOpenNotificationMobile,
  getCountUnRead,
  notificationCount,
}) => {
  const { push } = useHistory();
  const isAuth = false;
  const account = useSelector((state: any) => state.currentAccount);
  const userTheme = useSelector((state: any) => state.theme);
  const networkIsWrong = useSelector((state: any) => state.networkIsWrong);
  const { isDesktop, width } = useWindowSize();
  console.log('🚀 ~ width:', width);
  console.log('chainsdata', chains);
  const { chainId } = useAccount();

  console.log('🚀 ~ appheaderchainId:', chainId);
  const { myTier } = useSelector((state: any) => ({
    myTier: get(state, 'user.myTier', ''),
  }));
  const [isOpenNotify, setIsOpenNotify] = useState(false);
  const dispatch = useDispatch();
  const { isConnected } = useAccount();
  const { isUnauthenticated, isAuthenticated } = useAuth();
  // console.log('🚀 ~ isUnauthenticated:', isUnauthenticated);
  const balanceQuery = useGetBalances();
  // console.log('🚀 ~ balanceQuery:', balanceQuery!.data);
  const userLoginSuccess = false;
  const { openChainModal } = useChainModal();
  const { userBalance } = useSocket();

  const [notifyDetail, setNotifyDetail] = useState<any>();

  const [depositModalOpen, depositModalActions] = useDisclosure();

  useEffect(() => {
    if (userLoginSuccess && account) {
      getCountUnRead();
    }
    // eventBus.on(SocketEvent.delete_pair, () => {
    //   getCountUnRead();
    // });

    // eslint-disable-next-line
  }, [userLoginSuccess, account]);

  const isSupportedNetwork = (): boolean => {
    if (!chainId) return false;
    return Object.values(chains).some((chain) => chain.id === chainId);
  };
  console.log('🚀 ~ isSupportedNetwork ~ isSupportedNetwork:', isSupportedNetwork(), chainId);

  const handleChangeTheme = () => {
    if (userTheme === THEME_MODE.DARK) {
      dispatch(setTheme(THEME_MODE.LIGHT));
      setStorageTheme(THEME_MODE.LIGHT.toLocaleLowerCase());
    } else {
      dispatch(setTheme(THEME_MODE.DARK));
      setStorageTheme(THEME_MODE.DARK.toLocaleLowerCase());
    }
  };

  const renderRightMenuAction = () => {
    return (
      <div className="flex items-center ml-2 ">
        <button className="text-black dark:text-white" onClick={handleChangeTheme}>
          {userTheme === THEME_MODE.DARK ? (
            <Sun className="w-5 h-5 text-white" />
          ) : (
            <Moon className="w-5 h-5 text-black" />
          )}
        </button>

        {/* <span className="change-theme">
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  label: 'Orders',
                  onClick: () => {
                    // push('');
                  },
                  key: '1',
                },
                {
                  label: 'History',
                  onClick: () => {
                    // push('');
                  },
                  key: '2',
                },
              ],
            }}
          >
            <SettingOutlined />
          </Dropdown>
        </span> */}
      </div>
    );
  };

  const handleClickLogo = () => {
    push({ pathname: PATHS.default() });
  };

  const handleDepositModal = () => {
    if (isUnauthenticated) {
      notification.info({
        message: 'Wallet Connection',
        description: 'Please connect your wallet first',
        placement: 'bottomRight',
      });
    } else {
      depositModalActions.open();
    }
  };

  return (
    <div
      className={` flex border-b-0  w-[100%] z-30 border-t-0 border-l-0 bg-transparent border-r-0 shadow-lg border-dark-1/70 dark:border-black justify-between items-center p-4  font-semibold  `}
    >
      <div className="flex justify-between items-center w-1/3">
        <div
          className="flex items-center  justify-center cursor-pointer"
          onClick={() => handleClickLogo()}
        >
          <img src={VirtualIcon} alt="frac logo" />
          {userTheme === THEME_MODE.DARK ? (
            <span className="text-[28px] ml-2 text-white font-montserrat ">VDEX</span>
          ) : (
            <span className="text-[28px] ml-2  font-montserrat ">VDEX</span>
          )}
        </div>

        <div className="flex">{(isDesktop || width > 1024) && <SubMenu />}</div>
      </div>

      {width > 1024 ? (
        <div className="flex justify-around items-center">
          <Flex gap={12} align="center">
            <Button
              className="bg-[#00632B] py-4 min-w-[120px] text-white hover:bg-[#00682B]"
              onClick={handleDepositModal}
            >
              Deposit
            </Button>

            {/* {isSupportedNetwork() ? ( */}
            <ConnectButton showBalance={false} accountStatus={'full'} />
            {/* ) : ( */}
            {/* <Button
              size="large"
              type="primary"
              style={{ minWidth: 120, fontWeight: 500, backgroundColor: 'red' }}
              onClick={openChainModal}
            >
              Wrong Network
            </Button> */}
            {/* )} */}
            {/* <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus || authenticationStatus === 'authenticated');

                return (
                  <div
                    {...(!ready && {
                      'aria-hidden': true,
                      style: {
                        opacity: 0,
                        pointerEvents: 'none',
                        userSelect: 'none',
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button onClick={openConnectModal} type="button">
                            Connect Wallet
                          </button>
                        );
                      }

                      if (chain.unsupported) {
                        return (
                          <button onClick={openChainModal} type="button">
                            Wrong network
                          </button>
                        );
                      }

                      return (
                        <div style={{ display: 'flex', gap: 12 }}>
                          <button
                            onClick={openChainModal}
                            style={{ display: 'flex', alignItems: 'center' }}
                            type="button"
                          >
                            {chain.hasIcon && (
                              <div
                                style={{
                                  background: chain.iconBackground,
                                  width: 12,
                                  height: 12,
                                  borderRadius: 999,
                                  overflow: 'hidden',
                                  marginRight: 4,
                                }}
                              >
                                {chain.iconUrl && (
                                  <img
                                    alt={chain.name ?? 'Chain icon'}
                                    src={chain.iconUrl}
                                    style={{ width: 12, height: 12 }}
                                  />
                                )}
                              </div>
                            )}
                            {chain.name}
                          </button>

                          <button onClick={openAccountModal} type="button">
                            {account.displayName}
                            {account.displayBalance ? ` (${account.displayBalance})` : ''}
                          </button>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom> */}
            {userTheme === THEME_MODE.DARK ? (
              <h3 className="  text-white">
                {isAuthenticated && userBalance > 0 ? userBalance.toLocaleString() : 0} USD
              </h3>
            ) : (
              <h3 className=" text-black">
                {isAuthenticated && userBalance > 0 ? userBalance.toLocaleString() : 0} USD
              </h3>
            )}
          </Flex>

          {renderRightMenuAction()}
        </div>
      ) : (
        <span
          className="menu-icon-mobile"
          onClick={() => {
            setIsOpenMenuMobile(!isOpenMenuMobile);
          }}
        >
          <MenuIcon color={'#878787'} isOpen={isOpenMenuMobile} />
        </span>
      )}

      <DepositModal
        key={1}
        open={depositModalOpen}
        onClose={depositModalActions.close}
        onDepositSettle={depositModalActions.close}
      />
    </div>
  );
};

export default React.memo(AppHeader);
